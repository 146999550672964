import { Controller } from "@hotwired/stimulus";
import { toPng } from "html-to-image";

const CANVAS_MARGIN = 16;
export default class ElementDownloadController extends Controller<HTMLDivElement> {
  static targets = ["button"];
  static values = { filename: String };

  declare buttonTarget: HTMLButtonElement;
  declare filenameValue: string;

  connect(): void {
    this.buttonTarget.addEventListener("click", this.onButtonClick.bind(this));
  }

  onButtonClick(): void {
    this.buttonTarget.disabled = true; // Disable the button.
    setTimeout(() => {
      this.downloadImage()
        .then(() => {
          console.log(`Downloaded "${this.filenameValue}.png" successfully`);
  
          const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
  
          fetch('/charts/downloads', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-TOKEN': typeof csrfToken === 'string' ? csrfToken : ''
            },
            body: JSON.stringify({
              export_format: 'PNG'
            })
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to log chart download event');
            }
          })
          .catch(error => {
            console.error(error.message);
            // @ts-expect-error Rollbar is a global object.
            if (typeof window.Rollbar !== 'undefined') {
              // @ts-expect-error Rollbar as above.
              window.Rollbar.error(error.message);
            }
          });
        })
        .catch(error => {
          console.error("Error in onButtonClick:", error);
          // @ts-expect-error Rollbar is a global object.
          if (typeof window.Rollbar !== "undefined") {
            // @ts-expect-error as above.
            window.Rollbar.error(error);
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.buttonTarget.disabled = false; // Re-enable the button after a short delay.
          }, 250);
        });
    }, 0);
  }

  duplicateElement(element: HTMLElement): HTMLElement {
    const duplicate = element.cloneNode(true) as HTMLElement;
    duplicate.style.width = `${element.offsetWidth}px`;
    document.body.appendChild(duplicate);
    return duplicate;
  }

  async downloadImage(): Promise<void> {
    let element: HTMLElement | null = document.getElementById("element_download_wrapper_target");
    if (element === null) {
      throw new Error("Element element_download_wrapper_target not found");
    }
    element = this.duplicateElement(element);
    this.addLogoIcon(element);
    try {
      await this.withHiddenNoPrintElements(element, async () => {
        // See: https://www.npmjs.com/package/html-to-image#topng
        const dataUrl = await toPng(element, {
          cacheBust: true,
          backgroundColor: "white",
          width: element.offsetWidth + (CANVAS_MARGIN * 2),
          height: element.offsetHeight + (CANVAS_MARGIN * 5),
          style: {
            margin: `${CANVAS_MARGIN}px`,
            marginTop: `${CANVAS_MARGIN * 3}px`,
            width: `${element.offsetWidth}px`,
            height: `${element.offsetHeight}px`
          }
        });
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = typeof this.filenameValue === "string" ? this.filenameValue : "data.png";
        link.click();
      });
    } finally {
      element.remove();
    }
  }

  async withHiddenNoPrintElements(element, callback: () => Promise<void>): Promise<void> {
    const noPrintElements = element.querySelectorAll(".no-print");
    noPrintElements.forEach(element => {
      (element as HTMLElement).style.display = "none";
    });

    await callback().finally(() => {
      noPrintElements.forEach(element => {
        (element as HTMLElement).style.removeProperty("display");
      });
    });
  }

  addLogoIcon(element: HTMLElement): void {
    const svgElement = document.getElementById("site-logo")?.cloneNode(true) as HTMLElement;
    if (svgElement === null) {
      console.error("Site logo not found");
      // @ts-expect-error Rollbar is a global object.
      if (typeof window.Rollbar !== "undefined") {
        // @ts-expect-error as above.
        window.Rollbar.error("Site logo not found for chart download");
      }
      return;
    }
    svgElement.style.position = 'absolute';
    svgElement.style.top = `-${CANVAS_MARGIN * 2.5}px`;
    svgElement.style.right = `0`;
    svgElement.style.height = `${CANVAS_MARGIN * 2}px`;

    element.style.position = 'relative';
    element.appendChild(svgElement);
  }
}
