import { Controller } from '@hotwired/stimulus';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ShareNotice from '../share_notice/index';

// Controller to render the ShareNotice modal.
export default class ShareNoticeModalController extends Controller {
  static targets = ['modal', 'content'];
  static values = {
    userId: String,
    clipboardText: String,
    users: String,
    noticeId: String,
    formUrl: String
  };

  declare modalTarget: HTMLElement;
  declare contentTarget: HTMLElement;
  declare userIdValue: string;
  declare clipboardTextValue: string;
  declare usersValue: string;
  declare noticeIdValue: string;
  declare formUrlValue: string;

  connect(): void {
    const props = {
      userId: this.userIdValue,
      clipboardText: this.clipboardTextValue,
      users: this.usersValue,
      noticeId: this.noticeIdValue,
      formUrl: this.formUrlValue,
      closeModal: this.close.bind(this)
    };

    ReactDOM.render(
      React.createElement(ShareNotice, props),
      this.contentTarget
    );
  }

  open(): void {
    this.modalTarget.classList.remove('hidden');
  }

  close(): void {
    this.modalTarget.classList.add('hidden');
  }
}
