import type { Options } from 'highcharts';
import * as Highcharts from 'highcharts';
import { merge as deepMerge } from 'lodash';
// @ts-expect-error chartFontFamily is an array of strings
import { chartFontFamily } from './constants';
import exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import accessibility from 'highcharts/modules/accessibility';

// Create a type alias for Highcharts to avoid circular reference issues
type HighchartsType = typeof Highcharts;

export const highchartsOptionsWithExporting = function(Highcharts: HighchartsType, overrides: Options = {}): Options {
  exporting(Highcharts);
  offlineExporting(Highcharts);
  accessibility(Highcharts);

  const options = highchartsOptions(Highcharts, {
    // See https://api.highcharts.com/highcharts/exporting
    exporting: {
      chartOptions: {
        chart: {
          backgroundColor: 'white',
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            pointWidth: undefined, // Ensure this value is not defined.
          }
        }
      },
      scale: 1.25,
      sourceWidth: 1280,
      sourceHeight: 540,
      buttons: {
        contextButton: {
          enabled: false 
        },
        // Frustratingly, this is undocumented in the Highcharts API.
        exportButton: {
          // See https://api.highcharts.com/highcharts/exporting.buttons.contextButton.menuItems
          menuItems: [
            'downloadPNG',
            'downloadPDF'
          ],
          theme: {
            fill: 'transparent',
            // See https://api.highcharts.com/highcharts/plotOptions.series.states
            states: {
              hover: {
                fill: 'transparent'
              },
              select: {
                fill: 'transparent'
              }
            }
          },
          useHTML: true,
          text: `
            <button
              class="
                flex
                space-between
                items-center
                gap-x-1.5
                px-2
                py-1
                rounded
                bg-grey-200
              "
            >
              <span class="text-sm text-grey-700" style="font-weight: bold;">Export</span>
              <div class="h-4 w-4 grid place-content-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none" class="fill-current">
                  <path
                    d="M0.6 5.20981C0.759129 5.20981 0.911742 5.27081 1.02426 5.3794C1.13679 5.48799 1.2 5.63526 1.2 5.78883V9.26294C1.2 9.41651 1.26321 9.56378 1.37574 9.67237C1.48826 9.78096 1.64087 9.84196 1.8 9.84196H10.2C10.3591 9.84196 10.5117 9.78096 10.6243 9.67237C10.7368 9.56378 10.8 9.41651 10.8 9.26294V5.78883C10.8 5.63526 10.8632 5.48799 10.9757 5.3794C11.0883 5.27081 11.2409 5.20981 11.4 5.20981C11.5591 5.20981 11.7117 5.27081 11.8243 5.3794C11.9368 5.48799 12 5.63526 12 5.78883V9.26294C12 9.72364 11.8104 10.1655 11.4728 10.4912C11.1352 10.817 10.6774 11 10.2 11H1.8C1.32261 11 0.864773 10.817 0.527207 10.4912C0.189642 10.1655 -4.76837e-07 9.72364 -4.76837e-07 9.26294V5.78883C-4.76837e-07 5.63526 0.0632132 5.48799 0.175735 5.3794C0.288257 5.27081 0.44087 5.20981 0.6 5.20981Z"
                  ></path>
                  <path
                    d="M5.3966 6.13183L4.0226 4.80008C3.96665 4.7461 3.90024 4.70327 3.82715 4.67405C3.75405 4.64484 3.67571 4.6298 3.5966 4.6298C3.51748 4.6298 3.43914 4.64484 3.36605 4.67405C3.29295 4.70327 3.22654 4.7461 3.1706 4.80008C3.11465 4.85407 3.07028 4.91816 3.04 4.9887C3.00972 5.05924 2.99414 5.13484 2.99414 5.21119C2.99414 5.28753 3.00972 5.36314 3.04 5.43367C3.07028 5.50421 3.11465 5.5683 3.1706 5.62229L5.5706 7.93837C5.62766 7.99108 5.69494 8.0324 5.7686 8.05996C5.91467 8.11787 6.07852 8.11787 6.2246 8.05996C6.29825 8.0324 6.36553 7.99108 6.4226 7.93837L8.8226 5.62229C8.87883 5.56846 8.92347 5.50442 8.95393 5.43386C8.98439 5.3633 9.00008 5.28762 9.00008 5.21119C9.00008 5.13475 8.98439 5.05907 8.95393 4.98851C8.92347 4.91795 8.87883 4.85391 8.8226 4.80008C8.76682 4.74581 8.70046 4.70274 8.62734 4.67334C8.55423 4.64394 8.4758 4.62881 8.3966 4.62881C8.31739 4.62881 8.23897 4.64394 8.16585 4.67334C8.09273 4.70274 8.02637 4.74581 7.9706 4.80008L6.5966 6.13183L6.5966 0.579034C6.5966 0.425469 6.53338 0.278193 6.42086 0.169606C6.30834 0.0610192 6.15573 1.55255e-05 5.9966 1.55121e-05C5.83747 1.54987e-05 5.68485 0.0610192 5.57233 0.169606C5.45981 0.278193 5.3966 0.425469 5.3966 0.579034L5.3966 6.13183Z"
                  ></path>
                </svg>
              </div>
            </button>
          `
        }
      },
      // See https://api.highcharts.com/highcharts/exporting.menuItemDefinitions
      menuItemDefinitions: {
        // viewFullscreen: {},
        // printChart: {},
        // separator: {},
        downloadPNG: {
          onclick: onDownload({ exportFormat: 'PNG' }),
          text: 'Download chart as a PNG image'
        },
        // downloadJPEG: {},
        // downloadSVG: {},
        downloadPDF: {
          onclick: onDownload({ exportFormat: 'PDF' }),
          text: 'Download chart as a PDF'
        }
      }
    },
    navigation: {
      buttonOptions: {
        align: 'left',
        verticalAlign: 'bottom'
      },
      menuItemStyle: {
        fontFamily: chartFontFamily.join(','),
        fontSize: '14px',
        letterSpacing: '0',
        lineHeight: '18px',
        padding: '0.5em'
      },
      menuStyle: {
        padding: '0',
        backgroundColor: 'rgb(249 250 251)',
        border: '1px solid rgb(249 250 251)',
        color: 'rgb(75 85 99)',
        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
      },
      menuItemHoverStyle: {
        backgroundColor: 'rgb(229 231 235)',
        color: 'rgb(17 24 39)'
      }
    }
  });

  return deepMerge(options, overrides);
};

export const highchartsOptions = function(Highcharts: HighchartsType, overrides: Options = {}): Options {
  // Set default options for Highcharts.
  const options: Options = {
    // Tussell green
    colors: ['#0e9c1b'],
    chart: { backgroundColor: 'none' },
    lang: { numericSymbols: ['K', 'M', 'B', 'T', 'Q', 'Qi'] },
    tooltip: {
      backgroundColor: '#000000',
      borderColor: '#000000',
      formatter: function() {
        const date = Highcharts.dateFormat('%Y', new Date(this.x).getTime());
        return '<p style="color:white">' + date + ': £' + toHumanNumber(typeof this.y === 'number' ? this.y : 0) + '</p>';
      }
    },
    // Removes highcharts.com credit link
    credits: { enabled: false }
  };

  return deepMerge(options, overrides);
};

// Convert a number to a human-readable format with abbreviated units (K, M, B)
export const toHumanNumber = (number: number): string => {
  const units = ['B', 'M', 'K'];
  const divisors = [1e9, 1e6, 1e3];

  for (let i = 0; i < divisors.length; i++) {
    if (number >= divisors[i]) {
      return (number / divisors[i]).toFixed(1).replace(/\.0$/, '') + units[i];
    }
  }

  return number.toString();
};

// Map the export format to the MIME type
const formatMapper = (exportFormat: string): string => {
  switch (exportFormat) {
    case 'PNG':
      return 'image/png';
    case 'PDF':
      return 'application/pdf';
    default:
      // @ts-expect-error Rollbar is a global object.
      if (typeof window.Rollbar !== 'undefined') {
        // @ts-expect-error as above.
        window.Rollbar.error(`Unknown export format: ${exportFormat}`);
      }
      return 'image/png';
  }
};


// Download the chart as a file
const onDownload = (options: { exportFormat: string }) => function() {
  this.exportChart(
    {
      type: formatMapper(options.exportFormat),
      filename: 'chart'
    }
  );
  const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

  fetch('/charts/downloads', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': typeof csrfToken === 'string' ? csrfToken : ''
    },
    body: JSON.stringify({
      export_format: options.exportFormat
    })
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Failed to log chart download event');
    }
  })
  .catch(error => {
    console.error(error.message);
    // @ts-expect-error Rollbar is a global object.
    if (typeof window.Rollbar !== 'undefined') {
      // @ts-expect-error Rollbar as above.
      window.Rollbar.error(error.message);
    }
  });
};

export default {
  highchartsOptions,
  highchartsOptionsWithExporting,
  toHumanNumber
};
