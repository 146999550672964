import $ from 'jquery';

// AJAX request for displaying the data table below the value-volume or area
// graph. Fetches the data for a selected point on the graph, and the
// reordered data when a user clicks on a sortable column title.
export default function displayDataTable(url, data) {
  $.ajax({
    url,
    data,
    dataType: 'html',
    beforeSend: toggleOverlay,
    success: function(html) {
      $('#js-data-table').html(html);
    },
    error: function() {
      console.error('Failed to load data table');
      if (window.Rollbar) {
        window.Rollbar.error('Failed to load data table');
      }
    },
    complete: function() {
      $('#js-data-table').css({ opacity: 1 });
      toggleOverlay();
    }
  });
}

// Function to reveal or hide the loading overlay.
function toggleOverlay() {
  const loadingOverlay = document.getElementById('js-table-loading');
  if (loadingOverlay) {
    loadingOverlay.classList.toggle('hidden');
    loadingOverlay.classList.toggle('absolute');
  }
}
