import { Controller } from '@hotwired/stimulus';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import KeyDecisionMakersDownloadButton from '../key_decision_maker_download_button/index';

// Controller to render the KDM download button.
export default class KeyDecisionMakersDownloadController extends Controller {
  static values = {
    url: String,
    dataViewIdentifier: String,
    filtersString: String,
    order: String,
    options: String,
    title: String,
    downloadAccess: String,
    decisionMakerCount: String
  };

  // declare elementTarget: HTMLElement;
  declare urlValue: string;
  declare dataViewIdentifierValue: string;
  declare filtersStringValue: string;
  declare orderValue: string;
  declare optionsValue: string;
  declare titleValue: string;
  declare downloadAccessValue: string;
  declare decisionMakerCountValue: string;

  connect(): void {
    const props = {
      url: this.urlValue,
      dataViewIdentifier: this.dataViewIdentifierValue,
      filtersString: this.filtersStringValue,
      order: this.orderValue,
      options: this.optionsValue,
      title: this.titleValue,
      downloadAccess: JSON.parse(this.downloadAccessValue),
      decisionMakerCount: Number(this.decisionMakerCountValue)
    };

    ReactDOM.render(
      React.createElement(KeyDecisionMakersDownloadButton, props),
      this.element
    );
  }
}
