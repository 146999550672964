// This controller is for changing the opacity of the svg we use for our
// personalised checkboxes.
import { Controller } from '@hotwired/stimulus';

export default class CheckboxController extends Controller<HTMLFormElement> {
  static targets = ['checkbox', 'svg'];

  declare checkboxTarget: HTMLInputElement;
  declare svgTarget: HTMLImageElement;

  // Upon connection, this displays the checkbox correctly if checked
  connect(): void {
    if (this.checkboxTarget.checked) {
      this.svgTarget.style.opacity = '1';
    }
  }

  changeCheckbox(): void {
    if (this.checkboxTarget.checked) {
      this.svgTarget.style.opacity = '1';
    } else {
      this.svgTarget.style.opacity = '0';
    }
  }
}
