import { Controller } from "@hotwired/stimulus";

// This controller fetches and displays a framework summary in a collapsible, accordion-style UI.
// Although the framework summaries are not generated on the spot, we want to simulate this effect
// by displaying the summary after a timeout.
// Targets: 
//   - "section": the HTML element that will contain the summary text.
//   - "icon": the arrow icon that rotates upon toggle to show open/closed status.
//   - "clipboard": the clipboard icon used to copy the framework summary.
// Values:
//   - url (String): the endpoint from which to fetch the summary data.
//   - clipBoardEventUrl(String): the endpoint for sending an clipboard mixpanel event.
//   - isOpen (Boolean): a state flag that tracks whether the accordion is expanded or collapsed.

export default class FrameworkSummariesController extends Controller {
  REVEAL_TIMEOUT = 2000;
  static targets = ["section", "icon", "clipboard"];
  static values = {
    url: String,
    clipboardEventUrl: String,
    isOpen: { type: Boolean, default: false },
  };

  declare urlValue: string;
  declare clipboardEventUrlValue: string;
  declare sectionTarget: HTMLElement;
  declare iconTarget: HTMLElement;
  declare clipboardTarget: HTMLElement;
  declare isOpenValue: boolean;
  private hasExpandedBefore: boolean = false;

  initialize(): void {
    const handleClipboardClick = async () => {
      await this.copyToClipboardAndSendEvent();
    };
    // Handle when the clipboard icon is clicked.
    this.clipboardTarget.addEventListener("click", handleClipboardClick);
  }

  // Fetch the summary data from the FrameworkSummariesController.
  async getSummary(): Promise<string> {
    const response = await fetch(this.urlValue, {
      headers: {
        "Accept": "application/json"
      },
    });
    const data = await response.json();
    return data.summary;
  };

  async expandAccordion(): Promise<void> {
    // Set the state to open.
    this.isOpenValue = true;

    // Open the summary section and rotate the arrow icon.
    this.showAccordion();

    if (!this.hasExpandedBefore) {
      // Fetch the summary data and add it to the summary section,
      // after a reveal timeout.
      const summary = await this.getSummary();
      setTimeout(() => {
        this.sectionTarget.innerHTML = summary;

        this.showDisclaimer();
        this.showClipboard();

        // Set the flag to true after the first expansion.
        this.hasExpandedBefore = true;
      }, this.REVEAL_TIMEOUT);
    } else {
      // Show the disclaimer and clipboard icon immediately if already expanded before.
      this.showDisclaimer();
      this.showClipboard();
    };
  };

  hideClipboard(): void {
    this.clipboardTarget.classList.add("hidden");
  };

  showClipboard(): void {
    this.clipboardTarget.classList.remove("hidden");
  };

  collapseAccordion(): void {
    // Set the state to close.
    this.isOpenValue = false;

    this.hideAccordion();
    this.hideClipboard();
    this.hideDisclaimer();
  };

  showAccordion(): void {
    this.iconTarget.classList.add("rotate-90");
    this.sectionTarget.classList.remove("max-h-0", "opacity-0");
    this.sectionTarget.classList.add("max-h-fit", "opacity-100", "pt-3");
  };

  hideAccordion(): void {
    this.iconTarget.classList.remove("rotate-90");
    this.sectionTarget.classList.remove("max-h-fit", "opacity-100", "pt-3");
    this.sectionTarget.classList.add("max-h-0", "opacity-0");
  };

  // Display/hide the summary section depending on the open/closed state.
  onToggle(event: Event): void {
    // Only toggle if the event.target or any of its parents contain the "fw-summary-accordion-button" class.
    const hasButtonClass = event.target instanceof HTMLElement && event.target.closest(".fw-summary-accordion-button");
    if (!hasButtonClass) {
      return;
    };

    if (this.isOpenValue) {
      this.collapseAccordion();
    } else {
      this.expandAccordion();
    };
  };
  

  // Method to copy summary text to the clipboard and send a mixpanel event.
  async copyToClipboardAndSendEvent(): Promise<void> {
    navigator.clipboard.writeText(this.sectionTarget.innerText)
    fetch(this.clipboardEventUrlValue)
  };

  // Get the disclaimer element.
  disclaimerElement(): HTMLElement|null {
    return document.getElementById("fw-summary-disclaimer");
  };

  // Show the disclaimer element.
  showDisclaimer(): void {
    const disclaimerElement = this.disclaimerElement();
    disclaimerElement?.classList?.remove("hidden");
  };

  // Hide the disclaimer element.
  hideDisclaimer(): void {
    const disclaimerElement = this.disclaimerElement();
    disclaimerElement?.classList?.add("hidden");
  };
};
