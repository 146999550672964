import { Controller } from '@hotwired/stimulus';

// Controller to auto-submit a form on specific actions
export default class FormController extends Controller<HTMLFormElement>{
  static values = { action: String, method: String };
  declare readonly actionValue: string;
  declare readonly methodValue: string;
  declare readonly hasActionValue: boolean;
  declare readonly hasMethodValue: boolean;

  // Submit the form.
  // NOTE: You should use the Stimulus :prevent modifier to prevent the default
  //       action of the element.
  //       ie <button type="submit" data-action="form#submitForm:prevent">
  submitForm(): void {
    this.element.submit();
  }

  // Change the destination of the form, and then submit it.
  // NOTE: You should use the Stimulus :prevent modifier to prevent the default
  //       action of the element.
  //       ie <button
  //            type="submit"
  //            data-action="form#changeDestinationAndSubmit:prevent"
  //          >
  changeDestinationAndSubmit(): void {
    // Change the action
    if (this.hasActionValue) {
      this.element.setAttribute('action', this.actionValue);
    }

    // Change the method by replacing the hidden input value
    if (this.hasMethodValue) {
      for (const child of this.element.children) {
        if (child.getAttribute('name') === '_method') {
          child.setAttribute('value', this.methodValue);
        }
      }
    }

    // Submit the form
    this.submitForm();
  }
}