import { Controller } from '@hotwired/stimulus';
import areaChart from './charts/area_chart';
import displayDataTable from './tables/data_table';

// Controller to load a chart on Trend over Time tab
export default class TrendByBuyerChartController extends Controller<HTMLFormElement> {
  static values = {
    seriesArray: Array,
    bucketSize: String,
    categories: Array,
    url: String,
    uuid: String,
    dateline: String,
  };

  declare seriesArrayValue: string[];
  declare bucketSizeValue: string;
  declare categoriesValue: string[];
  declare urlValue: string;
  declare uuidValue: string;
  declare datelineValue: string;

  connect(): void {
    // Render the chart and the table
    areaChart(
      this.seriesArrayValue,
      this.categoriesValue,
      this.bucketSizeValue,
      this.uuidValue,
      this.datelineValue,
      this.urlValue,
    );

    // Paginate the data table below the value-volume graph.
    document.addEventListener('DOMContentLoaded', function() {
      const dataTable = document.getElementById('js-data-table');
      if (dataTable === undefined || dataTable === null) return;
  
      dataTable.addEventListener('click', function(e) {
        if (e.target === null) return;
        const target = e.target as HTMLAnchorElement;
        if (target.matches('#js-pagination a') || target.matches('th a')) {
          e.preventDefault();
          // NOTE: by_buyer: true is a flag I can send to the
          // TrendsTableController. It allows us to send the correct Mixpanel event.
          displayDataTable(target.href, { by_buyer: true });
        }
      });
    });
  }
}
