import React from 'react';
import PropTypes from 'prop-types';

// Component to render primary, secondary, tertiary and disabled buttons
// Usage:
// <Button type='primary' onClick={this.submitForm}>Submit</Button>
export default function Button(props) {
  const { type, onClick, disabled, children } = props;

  const baseStyles = 'flex items-center gap-x-1.5 py-1.5 px-2 rounded font-medium';

  const styles = {
    primary: `${baseStyles} bg-green-500 hover:bg-green-700 text-grey-50`,
    secondary: `${baseStyles} bg-grey-700 hover:bg-grey-800 text-grey-500`,
    tertiary: `${baseStyles} bg-grey-200 hover:bg-grey-400 text-grey-700`,
    disabled: `${baseStyles} bg-grey-200 text-grey-500 cursor-not-allowed`
  };

  const buttonStyles = disabled ? styles.disabled : styles[type];

  return (
    <button
      className={buttonStyles}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.array
};
