import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import Rails from '@rails/ujs';
import $ from 'jquery';

import Textarea from '../ui/textarea';
import SelectedCheckbox from '../ui/selected_checkbox';
import Button from '../ui/button';
import Cross from '../../assets/images/icons/cross.svg';
import EnvelopeIcon from '../../assets/images/icons/envelope.svg';
import LinkIcon from '../../assets/images/icons/link.svg';
import SearchIcon from '../../assets/images/icons/search.svg';

export default class ShareNotice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
      emailAddresses: [],
      suggestions: [],
      message: '',
      alert: null
    };
  }

  submitForm = () => {
    this.request = $.ajax({
      type: 'POST',
      url: this.props.formUrl,
      data: {
        authenticity_token: Rails.csrfToken() || '',
        id: parseInt(this.props.noticeId),
        user_id: parseInt(this.props.userId),
        email_addresses: this.state.emailAddresses,
        message: this.state.message
      },
      success: (_data) => {
        this.setState({
          alert: 'Email sent successfully.',
          inputValue: '',
          emailAddresses: [],
          message: ''
        });

        // clear the alert after 5 seconds so the message is gone if the modal is re-opened.
        setTimeout(() => this.setState({ alert: '' }), 5000);
      },
      error: (jqXhr, textStatus, errorMessage) => {
        console.error(textStatus, jqXhr.status, errorMessage);
        this.setState({ alert: jqXhr.responseJSON.errors[0] });
      }
    });
  };

  // // Methods for Autosuggest // //
  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const usersNotAlreadyAdded = this.userSuggestions().filter(user =>
      !this.state.emailAddresses.includes(user.email)
    );

    return usersNotAlreadyAdded.filter(user =>
      user.email.toLowerCase().includes(inputValue) ||
      user.fullName.toLowerCase().includes(inputValue)
    );
  };

  // Takes stringified list of users from props and formats it into
  // array of objects we can work with.
  // '[[email, first_name, last_name]]' => [{email: email, fullName: 'first_name last_name'}]
  userSuggestions = () => {
    const { users } = this.props;
    const userList = JSON.parse(users);

    const userSuggestions = userList.map((user) => {
      const email = user.shift();
      const fullName = user.join(' ');
      return { email, fullName };
    });

    return userSuggestions;
  };

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({ value }) => {
    const newSuggestions = this.getSuggestions(value);
    this.setState({ suggestions: newSuggestions });
  };

  clearSuggestions = () => {
    this.setState({ suggestions: [] });
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. We will clear it after selecting.
  getSuggestionValue = () => {
    return '';
  };

  // the dropdown options that appear when typing
  renderSuggestion = (suggestion) => {
    return (
      <>
        <h4 className='m-0'>
          {suggestion.fullName}
        </h4>
        <small>
          {suggestion.email}
        </small>
      </>
    );
  };

  onSuggestionSelected = (event, { method, suggestion }) => {
    if (method === 'enter') {
      event.preventDefault();
    }
    this.addEmailAddress(suggestion);
  };
  // // Methods for Autosuggest Above // //

  removeEmailAddress = (emailAddressToRemove) => {
    this.setState(prevState => ({
      emailAddresses: prevState.emailAddresses.filter(emailAddress => emailAddress !== emailAddressToRemove)
    }));
  };

  addEmailAddress = (suggestion) => {
    this.setState(prevState => ({
      emailAddresses: [...prevState.emailAddresses, suggestion.email]
    }));
  };

  // Show email addresses as list of checkboxes
  displayEmails = () => {
    if (this.state.emailAddresses.length < 1) { return; }

    return this.state.emailAddresses.map(emailAddress =>
      <SelectedCheckbox
        key={emailAddress}
        onDeselect={() => this.removeEmailAddress(emailAddress)}
        label={emailAddress}
      />
    );
  };

  onChangeMessage = (message) => {
    this.setState({ message });
  };

  onChangeInputValue = (_event, { newValue }) => {
    this.setState({
      inputValue: newValue
    });
  };

  buttonIsDisabled = () => {
    const hasNoEmailAddresses = this.state.emailAddresses.length === 0;
    const hasNoMessage = this.state.message.length === 0;
    return hasNoEmailAddresses || hasNoMessage;
  };

  renderInputComponent = inputProps => (
    <div className='relative'>
      <input {...inputProps} />
      <SearchIcon className='absolute h-3 w-3 flex right-3 top-1/2 transform -translate-y-1/2 fill-grey-700 pointer-events-none' />
    </div>
  );

  render = () => {
    const { inputValue, message, alert } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Search email or name',
      value: inputValue,
      onChange: this.onChangeInputValue
    };

    const titleAndCloseButton = (
      <div className='flex justify-between pb-5'>
        <h3 className='text-lg font-medium text-grey-900'>
          Share a contract
        </h3>
        <a onClick={this.props.closeModal} className='cursor-pointer'>
          <Cross className='fill-grey-500'/>
        </a>
      </div>
    );

    if (alert) {
      return (
        <div className='h-80 relative'>
          {titleAndCloseButton}
          {/* NOTE: The designs call for the copy to be centred in the middle
            modal ignoring the "Share a Contract" copy above. */}
          <div className='absolute h-full w-full top-0 left-0 right-0 bottom-0 flex pointer-events-none z-30'>
            <div className='m-auto text-green-500 font-normal'>
              Email sent successfully.
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {titleAndCloseButton}

        <div className='font-normal text-grey-900'>
          <div className='mb-2'>
            <label htmlFor='user'>Recipients</label>
          </div>

          <div className='flex flex-col space-y-1 mb-2'>
            {this.displayEmails()}
          </div>

          <div className='mb-2'>
            <Autosuggest
              suggestions={this.state.suggestions}
              onSuggestionSelected={this.onSuggestionSelected}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.clearSuggestions}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={this.renderSuggestion}
              renderInputComponent={this.renderInputComponent}
              inputProps={inputProps}
              theme={{
                input: 'w-full text-base text-grey-700 bg-grey-100 p-2 border-none rounded focus:ring-grey-200 focus:shadow-3xl',
                suggestion: 'p-2 bg-grey-100 hover:bg-grey-200 cursor-pointer',
                suggestionsContainer: 'absolute max-h-96 overflow-y-scroll mt-[1px] z-10 rounded-b'
              }}
            />
          </div>

          <div className='mb-2'>
            <label htmlFor='message'>Message</label>
          </div>

          <Textarea
            name='message'
            onChangeValue={(message) => this.onChangeMessage(message)}
            value={message}
            placeholder='Why are you sharing this contract?'
          />

          <div className='flex justify-between mt-5'>
            <Button
              type='primary'
              onClick={(e) => this.submitForm(e)}
              disabled={this.buttonIsDisabled()}
            >
              Share via email
              <EnvelopeIcon className="fill-current" />
            </Button>

            <Button
              type='tertiary'
              onClick={() => { navigator.clipboard.writeText(this.props.clipboardText); }}
            >
              Copy Contract URL
              <LinkIcon className="fill-current" />
            </Button>
          </div>
        </div>
      </div>
    );
  };
}

ShareNotice.propTypes = {
  noticeId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  formUrl: PropTypes.string.isRequired,
  clipboardText: PropTypes.string.isRequired,
  users: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired
};
