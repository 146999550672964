import { Controller } from '@hotwired/stimulus';
import {
  computePosition, offset, flip, shift, type Placement, type Middleware
} from '@floating-ui/dom';

// This is the type of the CustomEvent that is dispatched when a dropdown
// is opened. It contains a reference to the dropdown controller that is
// opening the dropdown and the groupKey of the dropdown group that the
// dropdown belongs to.
type DropdownCustomEvent = CustomEvent<{
  openingDropdownController: DropdownController,
  groupKey: string
}>;

// StimulusJS controller to handle the dropdown component.
export default class DropdownController extends Controller {
  // There is a slight delay between hovering over the button and the dropdown
  // opening. This is to prevent the dropdown from opening when the user is
  // just passing over the button on their way to something else.
  OPEN_DELAY: number = 50;
  // There is a slight delay between hovering out of the dropdown and the
  // dropdown closing. This is to prevent the dropdown from closing when the
  // user is moving their mouse from the button to the dropdown.
  CLOSE_DELAY: number = 250;
  // The options passed to the computePosition function.
  POSITION_OPTIONS: {
    placement: Placement,
    middleware: Middleware[]
  } = {
    placement: 'bottom-start', // Default to bottom-start
    middleware: [offset(6), flip(), shift({ padding: 5 })]
  };

  // Holding a reference to the timer allows us to cancel clear it if the user
  // hovers back over the button before the dropdown has closed.
  timer: NodeJS.Timeout | undefined;

  static targets = [ "button", "dropdown" ];
  declare dropdownTarget: HTMLDivElement;
  declare buttonTarget: HTMLLinkElement;

  static classes = [ "hover" ];
  declare hoverClasses: string[];

  static values =  { position: String, groupKey: String };
  declare positionValue: Placement;
  declare groupKeyValue: string;

  // The #update method is calculates the correct position for the dropdown
  // and is called when the dropdown is opened.
  async update(): Promise<void> {
    const options = this.POSITION_OPTIONS;
    options.placement = this.positionValue;
    const button = this.buttonTarget;
    const dropdown = this.dropdownTarget;
  
    try {
      const { x, y } = await computePosition(button, dropdown, options);
      Object.assign(dropdown.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    } catch (error) {
      console.error('Error computing position:', { cause: error });
    }
  }
  

  // Open the dropdown when the user hovers over the button.
  open(): void {
    clearTimeout(this.timer);
    setTimeout(
      () => {
        // Transition the button's styling to the hover state
        this.buttonTarget.classList.add(...this.hoverClasses);
        // Reveal the dropdown
        this.dropdownTarget.style.display = 'block';
        // Position the dropdown
        void this.update();
      },
      this.OPEN_DELAY
    );
    // Close any other dropdowns in the same group
    this.dispatch('open', {
      target: window,
      detail: { openingDropdownController: this, groupKey: this.groupKeyValue }
    });
  }

  // This is the CustomEvent handler that is called when another dropdown
  // is opened. If the other dropdown is in the same group, then we close
  // this dropdown.
  onGroupOpen(e: DropdownCustomEvent): void {
    const isCorrectGroup = e.detail.groupKey === this.groupKeyValue;
    const isNotThisDropdown = e.detail.openingDropdownController !== this;
    if (isCorrectGroup && isNotThisDropdown) {
      this.closeImmediately();
    }
  }

  // There is a slight delay between hovering out of the dropdown and
  // the dropdown closing. This is to prevent the dropdown from closing
  // when the user is moving their mouse from the button to the dropdown.
  close(): void {
    this.timer = setTimeout(
      this.closeImmediately.bind(this),
      this.CLOSE_DELAY
    );
  }

  // We also expose a way to close the dropdown immediately. This is used
  // when the user hovers on another dropdown button.
  closeImmediately(): void {
    // Transition the button's styling from the hover state
    this.buttonTarget.classList.remove(...this.hoverClasses);
    // Remove the dropdown
    this.dropdownTarget.style.display = 'none';
  }
}
