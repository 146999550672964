import React from 'react';
import PropTypes from 'prop-types';

// UI component for an html <textarea />
export default function Textarea(props) {
  const ROWS = 3;
  const classNames = `
    w-full border-none rounded p-2 pr-8
    text-base text-grey-700 bg-grey-100
    focus:ring-grey-200 focus:shadow-3xl
  `;

  const { name, placeholder, value, onChangeValue } = props;

  return (
      <textarea
        name={name}
        className={classNames}
        autoComplete='off'
        type='text'
        rows={ROWS}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChangeValue(e.target.value) }
      />
  );
}

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  className: PropTypes.string
};
