import { Controller } from '@hotwired/stimulus';
import * as Highcharts from 'highcharts';
import { highchartsOptions } from '../highchart_options';

// Controller to load a semi circle donut chart.
export default class SemiCircleDonutChartController extends Controller<HTMLFormElement>{
  static targets = ['container'];

  static values = {
    calloffCount: Number,
    contractAwardCount: Number
  };

  declare calloffCountValue: number;
  declare contractAwardCountValue: number;
  declare containerTarget: HTMLDivElement;

  connect(): void {
    const percentage = Math.round((this.calloffCountValue / this.contractAwardCountValue) * 100);

    Highcharts.chart(this.containerTarget, highchartsOptions(Highcharts, {
      chart: {
        plotBackgroundColor: 'none',
        plotBorderWidth: 0,
        plotShadow: false,
        spacing: [0, 0, 0, 0],
      },
      title: {
        text: percentage + '%',
        align: 'center',
        floating: true,
        margin: 0,
        verticalAlign: 'bottom',
        // This value is assigned to align the title to the bottom edge of the container.
        y: 31,
        style: {
          color: '#0E9C1B',
          fontWeight: 'normal',
          fontSize: '28px',
          lineHeight: 1
        }
      },
      tooltip: {
        backgroundColor: '#FAFBFA',
        borderColor: '#E9ECE9',
        borderRadius: 4,
        borderWidth: 1,
        outside: true,
        stickOnContact: true,
        padding: 5,
        shadow: {
          color: '#005E09',
          opacity: 0.02,
          offsetX: 0,
          offsetY: 0,
          width: 8
        },
        style: {
          color: '#607163',
          fontSize: '12px',
          fontWeight: 'bold'
        },
        formatter: function(): string {
          return `Via ${this.point.name}<br>Volume: ${this.y}`;
        }
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          borderRadius: 0,
          borderColor: 'none',
          dataLabels: {
            enabled: false,
            padding: 0
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '100%'],
          // If set to 200%, the graph is clipped slightly
          size: '198%',
          innerSize: '60%',
          slicedOffset: 0,
          states: {
            hover: {
              halo: {
                size: 0
              }
            }
          }
        }
      },
      colors: ['#0E9C1B', '#D1F2CF'],
      series: [{
        type: 'pie',
        name: 'Browser share',
        data: [
          ['framework', this.calloffCountValue],
          ['non-framework', this.contractAwardCountValue - this.calloffCountValue]
        ]
      }]
    }));
  }
}
