// This controller is used to enable a button once something has been selected.
// This will be used in SavedContracts and SavedSearches

import { Controller } from "@hotwired/stimulus";
export default class EnableActionButtonController extends Controller<HTMLFormElement> {
  static targets = ['allCheckbox', 'checkbox', 'button', 'svg'];
  declare checkboxTargets: HTMLInputElement[];
  declare buttonTargets: HTMLButtonElement[];
  declare allCheckboxTarget: HTMLInputElement;
  declare svgTargets: HTMLElement[];

  select(): void {
    const someChecked = this.checkboxTargets.some(c => c.checked);
  
    if (someChecked) {
      this.buttonTargets.forEach(button => {
        button.disabled = false;
        this.svgClass('fill-grey-50');
      });
    } else {
      this.buttonTargets.forEach(button => {
        button.disabled = true;
        this.svgClass('fill-grey-500');
      });
    }
  }

  selectAll(): void {
    if (this.allCheckboxTarget.checked) {
      this.buttonTargets.forEach(button => {
        button.disabled = false;
        this.svgClass('fill-grey-50');
      });
    } else {
      this.buttonTargets.forEach(button => {
        button.disabled = true;
        this.svgClass('fill-grey-500');
      });
    }
  }

  svgClass(className: string): void {
    this.svgTargets.forEach(svg => {
      const orginalClass = svg.classList.value;
      svg.classList.remove(orginalClass);
      svg.classList.add(className);
    });
  }
}
