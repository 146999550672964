import { Controller } from '@hotwired/stimulus';
import type { Placement, Middleware } from '@floating-ui/dom';
import { computePosition, autoUpdate, offset, flip, shift } from '@floating-ui/dom';
import morphdom from 'morphdom';

// This controller is used to open the notifications pane and poll the server
// for updates.
export default class NotificationsController extends Controller {
  // The options passed to the computePosition function.
  POSITION_OPTIONS: {
    placement: Placement,
    middleware: Middleware[]
  } = {
    placement: 'bottom-end',
    middleware: [offset(6), flip(), shift({ padding: 5 })]
  };

  static targets = [ 'button', 'pane', 'content', 'indicator' ];
  declare paneTarget: HTMLDivElement;
  declare buttonTarget: HTMLLinkElement;
  declare contentTarget: HTMLElement;
  declare indicatorTarget: HTMLElement;

  POLLING_INTERVAL = 2000;
  openClasses: string[] = ['!bg-green-700', '!text-grey-50'];
  isPolling = false;
  timer: NodeJS.Timeout | undefined;

  static values =  { isOpen: { type: Boolean, default: false }, url: String };
  declare isOpenValue: boolean;
  declare urlValue: string;

  // This clearup function removes the listeners added by autoUpdate.
  // We call it on disconnect to prevent memory leaks.
  cleanup: (() => void) | undefined;
  disconnect(): void {
    this.cleanup?.();
  }

  // This function allows us to update the pane position when the window is resized.
  updatePanePosition(): void {
    this.cleanup = autoUpdate(
      this.buttonTarget,
      this.paneTarget,
      // autoUpdate takes an update function that is called when the pane is opened.
      // It expects the function to return void, but ours returns a Promise<void>.
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      this.update.bind(this),
    );
  }

  // The #update method is calculates the correct position for the pane
  // and is called when the pane is opened.
  async update(): Promise<void> {
    const options = this.POSITION_OPTIONS;
    const button = this.buttonTarget;
    const pane = this.paneTarget;
    const {x, y} = await computePosition(button, pane, options);
    Object.assign(pane.style, { left: `${x}px`, top: `${y}px` });
  }

  // The #open method is called when the user clicks the open button.
  toggle(): void {
    if (this.isOpenValue) {
      this.close();
    } else {
      void this.open();
    }
  }

  // Opens the pane and starts polling the server for updates.
  async open(): Promise<void> {
    // Transition the button's styling to the active state
    this.buttonTarget.classList.add(...this.openClasses);
    // Reveal the pane
    this.paneTarget.classList.remove('hidden');
    // Remove class on next tick to trigger transition
    setTimeout(() => { this.paneTarget.classList.remove('opacity-0'); }, 0);
    // Position the pane
    this.updatePanePosition();
    // Set the state to open
    this.isOpenValue = true;
    // Start polling
    clearTimeout(this.timer);
    this.isPolling = true;
    void this.poll();
  }

  // Close the pane and starts polling the server for updates.
  close(): void {
    // Transition the button's styling from the active state
    this.buttonTarget.classList.remove(...this.openClasses);
    // Hide the pane
    this.paneTarget.classList.add('hidden', 'opacity-0');
    // Set the state to closed
    this.isOpenValue = false;
    // Stop polling
    this.isPolling = false;
  }

  // This function polls the server for updates and updates the DOM.
  async poll(): Promise<void> {
    try {
      // fetch the new content
      const response = await fetch(this.urlValue, {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      });

      const parsedResponse = await response.json();

      // update the DOM
      this.updateDOM(parsedResponse);

      // poll again if the pane is open
      if (this.isPolling) {
        this.timer = setTimeout(() => { void this.poll(); }, this.POLLING_INTERVAL);
      }
    } catch {
      const error = "<div>Something went wrong!</div>";
      morphdom(this.contentTarget, error);
    }
  }

  // This function takes a JSON response and updates the DOM.
  // The function expects a object with a `content` and `indicator` key.
  updateDOM(response: {content: string, indicator: string}): void {
    const content = response.content;
    morphdom(this.contentTarget, content);

    const indicator = response.indicator;
    morphdom(this.indicatorTarget, indicator);
  }
}
