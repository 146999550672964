import { Controller } from '@hotwired/stimulus';
import type NotificationsController from './notifications_controller';
import Rails from '@rails/ujs';

// This controller is used to create a download, update the notifications pane,
// and open then pane.
export default class DownloadsController extends Controller<HTMLFormElement> {
  BUTTON_TIMEOUT = 3000;
  static targets = ['button'];
  declare buttonTarget: HTMLButtonElement;
  static outlets = [ 'notifications' ];
  declare notificationsOutlet: NotificationsController;

  // This function submits the form, updates the notifications pane content,
  // and opens the pane.
  async submitAndRender(): Promise<void> {
    const form = this.element;
    // First, disable the button so we don't submit the form twice.
    this.buttonTarget.disabled = true;
    try {
      const response = await fetch(form.action, {
        method: form.method,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': Rails.csrfToken(),
        },
        // This as any is a hack to get around the fact that FormData might
        // contain a File object. In that case, FormData cannot be turned
        // into a URLSearchParams. We know that this form doesn't contain
        // a File object, but Typescript doesn't.
        // See: https://github.com/microsoft/TypeScript/issues/30584
        body: new URLSearchParams(new FormData(form) as any)
      });
      const parsedResponse = await response.json();
      // update the DOM first so we don't see a random flash of the new content
      this.notificationsOutlet.updateDOM(parsedResponse);
    } catch (error) {
      // This isn't such a big issue as we open the notifications pane anyway.
      // And it starts polling the server for updates.
      console.log(error);
    }
    // Finally open the pane
    void this.notificationsOutlet.open();
    // Re-enable the button after 3 seconds
    setTimeout(() => { this.buttonTarget.disabled = false; }, this.BUTTON_TIMEOUT);
  }
}