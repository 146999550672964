import { Controller } from '@hotwired/stimulus';

// Controller to handle revealing the loading overlay.
export default class LoadingOverlayController extends Controller {
  static values = { abort: Boolean };
  declare readonly abortValue: boolean;

  // Some views may want to abort the loading overlay, e.g. if the view is
  // already loading the overlay some other way.
  // The abort value is set in the Application Layout.
  // This allows specific Rails controllers to controller whether they want the
  // checkThenReveal() method to reveal the loading overlay or not.
  checkThenReveal(): void {
    if (this.abortValue) return;
    this.reveal();
  }

  // Reveal the loading overlay.
  reveal(): void {
    const loadingOverlay = document.getElementById('js-loading-overlay');
    if (loadingOverlay != null) {
      loadingOverlay.classList.remove('hidden');
      loadingOverlay.classList.add('absolute');
    }
  }
}
