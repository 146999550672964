import KeyDecisionMakerModuleFilters from '../../key_decision_maker_module_filters/index';

import React from 'react';
import ReactDOM from 'react-dom';

// Fetch the KDM table from the backend and replace the placeholder with
// the real data.
function render() {
  const moduleContainer = document.getElementById('js-kdm-module');
  const buyerId = moduleContainer.dataset.buyerId;
  const buyerName = moduleContainer.dataset.buyerName;
  const origin = moduleContainer.dataset.origin;
  const functions = JSON.parse(moduleContainer.dataset.functions);
  const kdmAccess = moduleContainer.dataset.kdmAccess === 'true';
  const kdmDownload = JSON.parse(moduleContainer.dataset.kdmDownload);
  let filters;

  ReactDOM.render(
    <KeyDecisionMakerModuleFilters
      filters={filters}
      functions={functions}
      buyerName={buyerName}
      buyerId={buyerId}
      kdmAccess={kdmAccess}
      origin={origin}
      kdmDownload={kdmDownload}
    />,
    moduleContainer
  );
}

export default render;
