import { Controller } from '@hotwired/stimulus';
import type { ActionEvent } from '@hotwired/stimulus';
import { computePosition, offset, flip, shift } from '@floating-ui/dom';
import type { Placement, Middleware } from '@floating-ui/dom';

// StimulusJS controller to handle the dropdown component.
export default class PopoverController extends Controller {
  POSITION_OPTIONS: {
    placement: Placement,
    middleware: Middleware[]
  } = {
      placement: 'bottom-start', // Default to bottom-start
      middleware: [offset(6), flip(), shift({ padding: 5 })]
    };

  // Holding a reference to the timer allows us to cancel clear it if the user
  // hovers back over the button before the dropdown has closed.
  timer: NodeJS.Timeout | undefined;

  static targets = ["button", "popover"];
  declare popoverTarget: HTMLDivElement;
  declare buttonTarget: HTMLLinkElement;

  static classes = ["hover"];
  declare hoverClasses: string[];

  static values = { position: String, groupKey: String };
  declare positionValue: Placement;
  declare groupKeyValue: string;

  // The #update method is calculates the correct position for the dropdown
  // and is called when the dropdown is opened.
  async update() : Promise<void> {
    const options = this.POSITION_OPTIONS;
    options.placement = this.positionValue;
    const button = this.buttonTarget;
    const popover = this.popoverTarget;
    const {x, y} = await computePosition(button, popover, options);
    Object.assign(popover.style, { left: `${x}px`, top: `${y}px` });
  }

  // Open the dropdown when the user clicks the button.
  open(): void {
    // Transition the button's styling to the hover state
    this.buttonTarget.classList.add(...this.hoverClasses);
    // Reveal the dropdown
    this.popoverTarget.style.display = 'block';
    // Position the dropdown
    void this.update();
  }

  close(): void {
    // Transition the button's styling from the hover state
    this.buttonTarget.classList.remove(...this.hoverClasses);
    // Remove the dropdown
    this.popoverTarget.style.display = 'none';
  }

  toggle(e: ActionEvent): void {
    e.preventDefault();

    if (this.popoverTarget.style.display !== 'block') {
      this.open();
    } else {
      this.close();
    }
  }
}
